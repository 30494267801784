import { ref } from "vue";

const userEmail = ref(null)
const userRoot = ref(null)
const partnerType = ref(null)
const provisioningKey = ref(null)
const impersonatePartnerId = ref(null)
const impersonatePartnerName = ref(null)
const registrationLink = ref(null)

export default function useGlobalData(){

    function setUserEmail(val){
        userEmail.value=val;
    }

    function setUserRoot(val){
        userRoot.value=val;
    }

    function setPartnerType(val){
        partnerType.value=val;
    }

    function setProvisioningKey(val){
        provisioningKey.value=val;
    }

    function setImpersonatePartnerId(val){
        impersonatePartnerId.value=val;
    }

    function setImpersonatePartnerName(val){
        impersonatePartnerName.value=val;
    }

    function setRegistrationLink(val){
        registrationLink.value=val;
    }

    return {userEmail, setUserEmail, userRoot, setUserRoot, partnerType, setPartnerType, provisioningKey, setProvisioningKey, impersonatePartnerId, setImpersonatePartnerId, impersonatePartnerName, setImpersonatePartnerName, registrationLink, setRegistrationLink}
}
