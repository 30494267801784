<template>
<!-- <router-view v-if="fronteggLoaded"></router-view>-->
 <router-view v-if="this.authState.user || this.noAuthPage"></router-view>
</template>

<script>
import axios from 'axios';
import useGlobalData from "@/state/global-data.js";
export default {
  name: 'App',
  data() {
    return {
      ...this.mapAuthState(),
      noAuthPage: false,
    };
  },
  beforeMount() {
    if (window.location.href.includes("/register")) {
      // Allow page to be viewed without frontegg authentication
      this.noAuthPage = true;
    }
    // Handle theme setting
    const theme = localStorage.getItem('theme');
    if (theme) {
      document.documentElement.setAttribute("data-layout-mode", theme);
    }

    // Handle auth
    const interval = setInterval(() => {
      if (window.location.href.includes("/account/login")) {
        // Remove impersonated partner if user is logged out
        localStorage.removeItem('impersonate-partner');
        return;
      }
      if (!this.authState.isLoading) {
        clearInterval(interval);
        if (this.authState.user) {
          if (this.noAuthPage) {
            // Logged in user is visiting no auth page, redirect to portal
            window.location.href = '/';
            return;
          }
          // Add access token as bearer
          axios.defaults.headers.common['authorization'] = 'Bearer ' + this.authState.user.accessToken
          let impersonatePartnerId = localStorage.getItem('impersonate-partner');
          if (impersonatePartnerId) {
            axios.defaults.headers.common['partner'] = impersonatePartnerId;
          }
          axios.get(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/user/info').then((data) => {
            const {setUserEmail, setUserRoot, setPartnerType, setProvisioningKey, setImpersonatePartnerId, setImpersonatePartnerName, setRegistrationLink} = useGlobalData();
            setUserEmail(data.data.email);
            setUserRoot(data.data.root);
            setPartnerType(data.data.type);
            setProvisioningKey(data.data.provisioning_key);
            setRegistrationLink(data.data.registration_link);
            if ('partner_id' in data.data && 'partner_name' in data.data) {
              setImpersonatePartnerId(data.data.partner_id);
              setImpersonatePartnerName(data.data.partner_name);
            }
            // Forcefully redirect to partners page
            if (data.data.type === 'distri' && !impersonatePartnerId) {
              if (!window.location.href.includes("/partners")) {
                window.location.href = '/partners';
              }
            }
          }).catch((er) => {
            console.log(er)
          });
        } else {
          if (!this.noAuthPage) {
            // Auth is required but user is not logged in, redirect to login page.
            window.location.href = '/account/login';
          }
        }
      }
    }, 50);
  }
}
</script>
